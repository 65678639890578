import React, { useReducer } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import SEO from '../components/seo';
import Layout from '../components/Layouts/Layout';
import CurrencyFormat from 'react-currency-format';

const numOfPeopleOptions = [
  { value: '', label: 'Select' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

const regSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  numberOfPeople: yup.string().required('Number of people is required'),
  email: yup
    .string()
    .email('Must be a valid email address')
    .required('Email is required'),
  phoneNumber: yup.string().required('Phone Number is required'),
});

const initialState = {
  firstName: '',
  lastName: '',
  numberOfPeople: '',
  email: '',
  phoneNumber: '',
  submitting: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateField':
      return { ...state, [action.field]: action.value };
    case 'startSubmit':
      return { ...state, submitting: true, succeeded: false };
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

const RSVP = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange = event => {
    const { name, value } = event.target;
    dispatch({ type: 'updateField', field: name, value });
  };

  const payload = {
    firstName: state.firstName,
    lastName: state.lastName,
    numberOfPeople: state.numberOfPeople,
    email: state.email,
    phoneNumber: state.phoneNumber,
  };

  const validateForm = async () => {
    const isValid = await regSchema.isValid(payload);
    if (!isValid) {
      toast.error('All fields are mandatory.', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
    return isValid;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      dispatch({ type: 'startSubmit' });
      try {
        await axios.post(`${process.env.GATSBY_URL}/api/rsvp`, payload);
        dispatch({ type: 'successSubmit' });
        toast.success('Thank you for your RSVP.', {
          position: 'bottom-center',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        // reset the form
        dispatch({ type: 'reset' });
      } catch (error) {
        toast.error('Something went wrong. Please try again.', {
          position: 'bottom-center',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    }
  };

  return (
    <Layout>
      <SEO title='RSVP' />
      <section className='relative bg-gray-700 text-white'>
        <div className='container mx-auto px-4 lg:pt-24 lg:pb-64 py-16'>
          <div className='flex flex-wrap pt-16 justify-center'>
            <div className='w-full lg:w-6/12 mb-6 shadow-lg rounded-lg bg-gray-300'>
              <div className='flex-auto p-5'>
                <div className='text-center text-gray-700'>
                  <h3 className='text-lg font-bold text-orange-500 text-center'>
                    Use the form below to RSVP for the event.
                  </h3>
                </div>
                <hr className='my-6 border-gray-400' />
                <form onSubmit={handleSubmit}>
                  <div className='w-full p-2'>
                    <label
                      className='block  text-gray-700 text-xs font-bold mb-2'
                      htmlFor='first-name'
                    >
                      First Name
                    </label>
                    <input
                      name='firstName'
                      type='text'
                      value={state.firstName}
                      onChange={handleChange}
                      className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                      placeholder='First Name'
                      required
                    />
                  </div>
                  <div className='w-full p-2'>
                    <label
                      className='block text-gray-700 text-xs font-bold mb-2'
                      htmlFor='last-name'
                    >
                      Last Name
                    </label>
                    <input
                      name='lastName'
                      type='text'
                      value={state.lastName}
                      onChange={handleChange}
                      className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                      placeholder='Last Name'
                      required
                    />
                  </div>
                  <div className='w-full p-2'>
                    <label
                      htmlFor='numberOfPeople'
                      className='block text-gray-700 text-xs font-bold mb-2'
                    >
                      How many people are you RSVPing for? (including yourself)
                    </label>
                    <select
                      name='numberOfPeople'
                      className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                      value={state.numberOfPeople}
                      required
                      onChange={handleChange}
                    >
                      {numOfPeopleOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='w-full p-2'>
                    <label
                      className='block  text-gray-700 text-xs font-bold mb-2'
                      htmlFor='email'
                    >
                      Email
                    </label>
                    <input
                      name='email'
                      type='email'
                      value={state.email}
                      onChange={handleChange}
                      className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                      placeholder='Email'
                    />
                  </div>
                  <div className='w-full p-2'>
                    <label
                      className='block  text-gray-700 text-xs font-bold mb-2'
                      htmlFor='phone'
                    >
                      Phone Number
                    </label>
                    <CurrencyFormat
                      name='phoneNumber'
                      format='+1 (###) ###-####'
                      mask='_'
                      value={state.phoneNumber}
                      onChange={handleChange}
                      placeholder='Phone Number'
                      className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                      required
                    />
                  </div>

                  <hr className='my-6 border-gray-400' />

                  <div className='text-center mt-6'>
                    <button
                      className='bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-5 ease-linear transition-all duration-150'
                      type='submit'
                      disabled={state.submitting}
                    >
                      {state.submitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RSVP;
